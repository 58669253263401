import request from '@/utils/request'
export function load_opportunity_list(data) {
  return request({
    url: '/opportunity-list',
    method: 'post',
    data:data
    
  })
}
export function load_unfortunate_attempt() {
  return request({
    url: '/unfortunate-attempt',
    method: 'post',
    
  })
}


export function load_opportunity_details(data) {
  return request({
    url: '/opportunity-details',
    method: 'post',
    data:data
  })
}
export function add_market(data) {
  return request({
    url: '/add-market',
    method: 'post',
    data:data
  })
}
export function delete_market(data) {
  return request({
    url: '/delete-market',
    method: 'post',
    data:data
  })
}

export function search(data) {
  return request({
    url: '/search',
    method: 'post',
    data:data
  })
}

export function get_branch_details(data) {
  return request({
    url: '/branch',
    method: 'post',
    data:data
  })
}

export function get_added_activity_rate(data) {
  return request({
    url: '/added-activity-rate',
    method: 'post',
    data:data
  })
}

export function get_deleted_activity_rate(data) {
  return request({
    url: '/deleted-activity-rate',
    method: 'post',
    data:data
  })
}


export function get_activity_map(data) {
  return request({
    url: '/activity-map',
    method: 'post',
    data:data
  })
}

export function get_activity_address_change(data) {
  return request({
    url: '/activity-address-change',
    method: 'post',
    data:data
  })
}

export function get_area_activity(data) {
  return request({
    url: '/area-activity',
    method: 'post',
    data:data
  })
}

export function get_dashboard(data) {
  return request({
    url: '/dashboard',
    method: 'post',
    data:data
  })
}


export function get_business_change(data) {
  return request({
    url: '/business-change',
    method: 'post',
    data:data
  })
}

export function get_added_activities_stat(data) {
  return request({
    url: '/added-activity-stat',
    method: 'post',
    data:data
  })
}


export function get_deleted_activities_stat(data) {
  return request({
    url: '/deleted-activity-stat',
    method: 'post',
    data:data
  })
}

export function added_activity_trend() {
  return request({
    url: '/added-activity-trend',
    method: 'post',
  })
}
export function deleted_activity_trend() {
  return request({
    url: '/deleted-activity-trend',
    method: 'post',
  })
}

export function get_block_details(data) {
  return request({
    url: '/block-details',
    method: 'post',
    data:data
  })
}

export function get_block_summary() {
  return request({
    url: '/block-summary',
    method: 'post',
    
  })
}